<template>
  <div id="form-a-company-not-found">
    <slide-heading heading="This paperwork is a mystery our guide can’t quite solve yet." />

    <div class="description">
      <p>
        Our walk-through guide doesn't offer PDF filing for formation paperwork for this
        state—either the state requires special online filing, or it’s a complex form that
        needs additional care.
      </p>
    </div>
    <b-button
      class="float-right"
      variant="primary"
      aria-label="continue button"
      @click="$emit('next-slide')"
    >
      <span>
        Continue
      </span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'FormACompanyNotFound',
  components: {
    SlideHeading: () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slide: null,
  },
}
</script>
